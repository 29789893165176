import React, { Component } from "react";
import { ResponsivePie } from "@nivo/pie";
import URLs from "../../utils/apis";
import axios from "axios";
import { message } from "antd";

export default class DistrictWiseChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: 0,
      volunteers: 0,
      loading: false,
      graphData: [],
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    let admin_id = localStorage.getItem("admin_id");
    let a_token = localStorage.getItem("a_token");
    axios
      .get(
        URLs.get_dashboard_data + "?admin_id=" + admin_id + "&token=" + a_token
      )
      .then((response) => {
        console.log("chart response", response);
        if (response.data.status == "1") {
          let data = [];
          for (let item of response.data.data.graphData) {
            let obj = {
              id: item.title,
              label: item.title,
              value: item.total,
              color: "hsl(357, 70%, 50%)",
            };
            data.push(obj);
          }
          this.setState({
            graphData: data,
            loading: false,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  render() {
    const data = [
      {
        id: "Akola",
        label: "Akola",
        value: 90,
        color: "hsl(357, 70%, 50%)",
      },
      {
        id: "Amerawati",
        label: "Amerawati",
        value: 20,
        color: "hsl(62, 70%, 50%)",
      },
      {
        id: "Beed",
        label: "Beed",
        value: 478,
        color: "hsl(69, 70%, 50%)",
      },
      {
        id: "Bandara",
        label: "Bandara",
        value: 224,
        color: "hsl(314, 70%, 50%)",
      },
      {
        id: "Aurangabad",
        label: "Aurangabad",
        value: 128,
        color: "hsl(269, 70%, 50%)",
      },
    ];
    return (
      <div style={{ width: "100%", minHeight: "100px" }}>
        {this.state.graphData.length > 0 ? (
          <div className="row">
            {this.state.graphData.map((item, index) => {
              return (
                <div className="col-lg-4" key={index}>
                  <div>
                    <label>
                      <strong>{item.label}:</strong> {item.value}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p>Not available..</p>
        )}
        {/* {this.state.graphData.length > 0 ? (
          <ResponsivePie
            data={this.state.graphData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: "color",
              modifiers: [["darker", 2]],
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            fill={[
              {
                match: {
                  id: "ruby",
                },
                id: "dots",
              },
              {
                match: {
                  id: "c",
                },
                id: "dots",
              },
              {
                match: {
                  id: "go",
                },
                id: "dots",
              },
              {
                match: {
                  id: "python",
                },
                id: "dots",
              },
              {
                match: {
                  id: "scala",
                },
                id: "lines",
              },
              {
                match: {
                  id: "lisp",
                },
                id: "lines",
              },
              {
                match: {
                  id: "elixir",
                },
                id: "lines",
              },
              {
                match: {
                  id: "javascript",
                },
                id: "lines",
              },
            ]}
            legends={[
              {
                anchor: "bottom",
                direction: "row",
                justify: false,
                translateX: 0,
                translateY: 56,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        ) : (
          <p>Not available..</p>
        )} */}
      </div>
    );
  }
}
