import React, { Component } from "react";
import URLs from "../utils/apis";
import axios from "axios";
import { message } from "antd";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      total_users: 0,
    };
  }
  componentDidMount() {
    this.get_data();
    this.get_list();
  }
  get_list = () => {
    let admin_id = localStorage.getItem("admin_id");
    let district_id = localStorage.getItem("ad_id");
    let a_token = localStorage.getItem("a_token");
    axios
      .get(
        URLs.get_volunteers +
          "?district_id=" +
          district_id +
          "&token=" +
          a_token
      )
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            total_users: response.data.data.length,
          });
          return;
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  get_data = () => {
    let params = {
      admin_id: localStorage.getItem("admin_id"),
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            records: response.data.data,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  render() {
    const countStyle = {
      background: "#6201ed",
      borderRadius: "50%",
      color: "#fff",
      padding: "3px 3px 2px 3px",
      fontSize: 13,
      padding: "2px 5px",
    };
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        {/* <div className="sidebar-profile">
          <div className="d-flex align-items-center justify-content-between">
            <img src="../../images/faces/face5.jpg" alt="profile" />
            <div className="profile-desc">
              <p className="name mb-0">Louis Scott</p>
              <p className="designation mb-0">Sales Manager</p>
            </div>
          </div>
        </div> */}
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="/dashboard">
              <box-icon
                type="solid"
                name="check-shield"
                color="#6201ed"
                className="menu-icon"
                size="18px"
              ></box-icon>
              <span className="menu-title">Dashboard</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/candidates">
              <box-icon
                type="solid"
                name="group"
                color="#6201ed"
                size="18px"
                className="menu-icon"
              ></box-icon>
              <span className="menu-title">
                Candidates{" "}
                <span style={countStyle}>{this.state.records.length}</span>
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/volunteer-users">
              <box-icon
                type="solid"
                name="user-circle"
                color="#6201ed"
                size="18px"
                className="menu-icon"
              ></box-icon>
              <span className="menu-title">
                Registered Volunteers{" "}
                <span style={countStyle}>{this.state.total_users}</span>
              </span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/settings">
              <box-icon
                type="solid"
                name="cog"
                color="#6201ed"
                size="18px"
                className="menu-icon"
              ></box-icon>
              <span className="menu-title">Settings</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
