import React, { Component } from "react";
import { message, Spin } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: "",
      email: "",
      password: "",
      otp: "",
      loading: false,
      isOtpSent: false,
      otpValue: "",
      otpsent: false,
      isOtpVerified: false,
      new_password: "",
      confirm_password: "",
      id: 0,
    };
  }
  componentDidMount() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const value = urlParams.get("type");
    console.log("type = ", value);
  }
  verifyotp = () => {
    if (!this.state.email) {
      message.warning("Email address is required.");
      return;
    }
    if (this.validateEmail(this.state.email) == false) {
      message.warning("Invalid format of email address.");
      return;
    }
    if (!this.state.otpValue) {
      message.warning("OTP is required.");
      return;
    }
    let params = {
      email: this.state.email,
      otp: this.state.otpValue,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.verfity_forgot_otp, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({
            loading: false,
            isOtpVerified: true,
            id: response.data.secret,
          });
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  sendotp = () => {
    if (!this.state.email) {
      message.warning("Email address is required.");
      return;
    }
    if (this.validateEmail(this.state.email) == false) {
      message.warning("Invalid format of email address.");
      return;
    }
    let params = {
      email: this.state.email,
    };
    console.log("params", params);
    this.setState({ loading: true });
    axios
      .post(URLs.send_forgot_otp, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({ loading: false, isOtpSent: true });
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  change_password = () => {
    if (!this.state.id) {
      message.warning("Invalid request. Please try again.");
      return;
    }
    if (this.validateEmail(this.state.email) == false) {
      message.warning("Invalid format of email address.");
      return;
    }
    if (!this.state.new_password) {
      message.warning("New Password is required");
      return;
    }
    if (!this.state.confirm_password) {
      message.warning("Confirm Password is required");
      return;
    }
    if (this.state.confirm_password != this.state.new_password) {
      message.warning("Both password new and cofirm password is not matching.");
      return;
    }
    let params = {
      secret: this.state.id,
      new_password: this.state.new_password,
      confirm_password: this.state.confirm_password,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.change_password, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({ loading: false }, () => {
            setTimeout(() => {
              window.location.assign("/");
            }, 2000);
          });
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div className="login-page">
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
              <div className="row flex-grow">
                <div className="col-lg-6 d-flex align-items-center justify-content-center m-auto">
                  <div className="auth-form-transparent text-left p-3">
                    <div className="brand-logo">
                      <img
                        src="./assets/images/sahaas-logo.png"
                        alt="logo"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "auto",
                          maxWidth: 95,
                        }}
                      />
                    </div>
                    <h4 className="text-center">Admin Forgot Password</h4>
                    <h6 className="text-center" style={{ fontWeight: "400" }}>
                      Happy to see you again!
                    </h6>
                    <Spin spinning={this.state.loading}>
                      {this.state.isOtpVerified == true ? (
                        <form className="pt-3">
                          <div className="form-group">
                            <label for="exampleInputEmail">New Password</label>
                            <div className="input-group">
                              <div className="input-group-prepend bg-transparent">
                                <span className="input-group-text bg-transparent border-right-0">
                                  <box-icon
                                    type="solid"
                                    name="user"
                                    size="17px"
                                    color="#6201ed"
                                    style={{
                                      position: "relative",
                                      left: 6,
                                    }}
                                  ></box-icon>
                                </span>
                              </div>
                              <input
                                type="password"
                                className="form-control form-control-lg border-left-0"
                                id="exampleInputEmail"
                                placeholder="New Password"
                                value={this.state.new_password}
                                onChange={(e) => {
                                  this.setState({
                                    new_password: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label for="exampleInputEmail">
                              Confirm Password
                            </label>
                            <div className="input-group">
                              <div className="input-group-prepend bg-transparent">
                                <span className="input-group-text bg-transparent border-right-0">
                                  <box-icon
                                    type="solid"
                                    name="user"
                                    size="17px"
                                    color="#6201ed"
                                    style={{
                                      position: "relative",
                                      left: 6,
                                    }}
                                  ></box-icon>
                                </span>
                              </div>
                              <input
                                type="password"
                                className="form-control form-control-lg border-left-0"
                                id="exampleInputEmail"
                                placeholder="Confirm Password"
                                value={this.state.confirm_password}
                                onChange={(e) => {
                                  this.setState({
                                    confirm_password: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="my-3">
                            <button
                              className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                              type="button"
                              onClick={() => this.change_password()}
                            >
                              Change Password
                            </button>
                          </div>
                        </form>
                      ) : (
                        <form className="pt-3">
                          <div className="form-group">
                            <label for="exampleInputEmail">Email ID</label>
                            <div className="input-group">
                              <div className="input-group-prepend bg-transparent">
                                <span className="input-group-text bg-transparent border-right-0">
                                  <box-icon
                                    type="solid"
                                    name="user"
                                    size="17px"
                                    color="#6201ed"
                                    style={{
                                      position: "relative",
                                      left: 6,
                                    }}
                                  ></box-icon>
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control form-control-lg border-left-0"
                                id="exampleInputEmail"
                                placeholder="Email ID"
                                value={this.state.email}
                                onChange={(e) => {
                                  this.setState({ email: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                          {this.state.isOtpSent == true && (
                            <div className="form-group">
                              <label for="exampleInputEmail">OTP</label>
                              <div className="input-group">
                                <div className="input-group-prepend bg-transparent">
                                  <span className="input-group-text bg-transparent border-right-0">
                                    <box-icon
                                      type="solid"
                                      name="user"
                                      size="17px"
                                      color="#6201ed"
                                      style={{
                                        position: "relative",
                                        left: 6,
                                      }}
                                    ></box-icon>
                                  </span>
                                </div>
                                <input
                                  type="number"
                                  className="form-control form-control-lg border-left-0"
                                  id="exampleInputEmail"
                                  placeholder="OTP"
                                  value={this.state.otpValue}
                                  onChange={(e) => {
                                    let value = e.target.value.slice(0, 6);
                                    this.setState({ otpValue: value });
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <div className="my-3">
                            {this.state.isOtpSent == true ? (
                              <button
                                className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                type="button"
                                onClick={() => this.verifyotp()}
                              >
                                Verfiy OTP
                              </button>
                            ) : (
                              <button
                                className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                                type="button"
                                onClick={() => this.sendotp()}
                              >
                                Send OTP
                              </button>
                            )}
                          </div>
                        </form>
                      )}
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    );
  }
}
