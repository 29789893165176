let BASE_URL = "https://sapi.affixtech.in/api/";
let BASE_URL2 = "https://sapi.affixtech.in/";
let URLs = {
  login: BASE_URL + "admin/login",
  // forgot password
  send_forgot_otp: BASE_URL + "admin/forgot_email_send_otp",
  verfity_forgot_otp: BASE_URL + "admin/forgot_verfity_otp",
  change_password: BASE_URL + "admin/forgot_change_password",

  get_dashboard_data: BASE_URL + "admin/get-dashboard-count",
  get_admin_profile_details: BASE_URL + "admin/get_profile_details",
  // admin
  get_volunteers: BASE_URL + "admin/volunteer/list",
  add_new_volunteer: BASE_URL + "admin/volunteer/add",
  update_volunteer_details: BASE_URL + "admin/volunteer/update",
  update_volunteer_status: BASE_URL + "admin/volunteer/update_status",

  update_details: BASE_URL + "admin/update_profile_details",
  update_password: BASE_URL + "admin/update_password",
  get_disability_list: BASE_URL + "admin/get_disability_list",
  get_taluka_list: BASE_URL + "admin/get_taluka_list",
  get_district_list: BASE_URL + "admin/get_district_list",
  get_state_list: BASE_URL + "admin/get_state_list",
  get_candidate_list: BASE_URL + "admin/candidate/list",
  update_candidate_details: BASE_URL + "admin/candidate/update",
  BASE_URL: BASE_URL2,

  send_email_otp_setting: BASE_URL + "admin/setting_email_send_otp",
  verify_email_otp_setting: BASE_URL + "admin/setting_email_verify_otp",

  send_mobile_otp_setting: BASE_URL + "admin/setting_mobile_send_otp",
  verify_mobile_otp_setting: BASE_URL + "admin/setting_mobile_verify_otp",

  generate_single_users_pdf: BASE_URL + "admin/generate_single_pdf",
  generate_tabular_users_pdf: BASE_URL + "admin/generate_tabular_pdf",
};

export default URLs;
