import React, { Component } from "react";
import { Switch, message } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";
import helperfunctions from "../../utils/functions";

export default class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sahaas_id: "",
      candidate_id: "",
      religion_list: [
        "Hindus",
        "Muslims",
        "Christians",
        "Sikhs",
        "Buddhists",
        "Buddhists",
        "Jains",
        "Other religions",
      ],
      language_list: [
        "Hindi",
        "English",
        "Bengali",
        "Marathi",
        "Telugu",
        "Tamil",
        "Gujrati",
        "Urdu",
        "Kannada",
        "Odia",
        "Malayalam",
        "Punjabi",
        "Assamese",
        "Maithili",
        "Meitei",
        "Sanskrit",
      ],
      bloodGroupList: ["A+", "A-", "B+", "B-", "O+", "O-", "AB+", "AB-"],
      have_adhar: false,
      adhar_no: "",
      name: "",
      gender: "",
      dob: "",
      blood_group: "",
      marital_status: "",
      religion: "",
      caste: "",
      language: "",
      personal_contact: "",
      photo_gallery: "",
      candidate_id: 0,
      photo_gallery_url: "",
      own_age: 0,
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    // Get the value of the 'id' parameter
    const cid = searchParams.get("cid");
    if (cid) {
      this.setState(
        {
          candidate_id: cid,
        },
        () => this.get_list()
      );
    } else {
      window.location.assign("/submitted-forms");
    }
    console.log("id", cid);
  }
  get_list = () => {
    let params = {
      admin_id: localStorage.getItem("admin_id"),
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.candidate_id == this.state.candidate_id) {
              let photo_gallery_url = item.photo_gallery
                ? URLs.BASE_URL + item.photo_gallery
                : "";
              this.setState({
                sahaas_id: item.sahaas_id,
                have_adhar: item.have_adhar == "Yes" ? true : false,
                adhar_no: item.adhar_no,
                name: item.name,
                gender: item.gender,
                dob: item.date_of_birth,
                blood_group: item.blood_group,
                marital_status: item.marital_status,
                religion: item.religion,
                caste: item.caste,
                language: item.language,
                personal_contact: item.personal_contact,
                photo_gallery_url: photo_gallery_url,
              });
              return;
            }
          }
          // this.setState({
          //   sahaas_id: response.data.data,
          // });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  handleImageChange = (e) => {
    this.setState({
      photo_gallery: e.target.files[0],
    });
  };
  validateAadhaar = (aadhaarNumber) => {
    // Regex pattern for Aadhaar number validation
    var aadhaarPattern = /^\d{4}\s\d{4}\s\d{4}$/;

    // Check if the provided number matches the pattern
    if (aadhaarPattern.test(aadhaarNumber)) {
      return true; // Valid Aadhaar number
    } else {
      return false; // Invalid Aadhaar number
    }
  };
  submit = () => {
    if (this.state.have_adhar == true) {
      if (!this.state.adhar_no) {
        message.warning("Adhar No is required.");
        return;
      } else if (this.validateAadhaar(this.state.adhar_no) == false) {
        message.warning("Invalid format of Aadhaar Card No.");
        return;
      }
    }
    if (!this.state.name) {
      message.warning("Name is required");
      return;
    }
    if (!this.state.gender) {
      message.warning("Gender is required");
      return;
    }
    if (!this.state.dob) {
      message.warning("Date of birth is required");
      return;
    }
    if (!this.state.blood_group) {
      message.warning("Blood Group is required");
      return;
    }
    if (!this.state.marital_status) {
      message.warning("Marital Status is required");
      return;
    }
    if (!this.state.religion) {
      message.warning("Religion is required");
      return;
    }
    if (!this.state.caste) {
      message.warning("Caste is required");
      return;
    }
    if (!this.state.language) {
      message.warning("Language is required");
      return;
    }
    if (
      helperfunctions.containsOnlyNumbers(this.state.personal_contact) == false
    ) {
      message.warning("Invalid Personal contact no format.");
      return;
    }
    if (!this.state.personal_contact) {
      message.warning("Personal contact no is required");
      return;
    }
    if (this.state.personal_contact.length != 10) {
      message.warning("Invalid Personal contact no format.");
      return;
    }
    let formdata = new FormData();
    formdata.append("have_adhar", this.state.have_adhar == true ? "Yes" : "No");
    formdata.append("adhar_no", this.state.adhar_no);
    formdata.append("name", this.state.name);
    formdata.append("gender", this.state.gender);
    formdata.append("dob", this.state.dob);
    formdata.append("blood_group", this.state.blood_group);
    formdata.append("marital_status", this.state.marital_status);
    formdata.append("religion", this.state.religion);
    formdata.append("caste", this.state.caste);
    formdata.append("language", this.state.language);
    formdata.append("personal_contact", this.state.personal_contact);
    formdata.append("id", this.state.candidate_id);
    formdata.append("photo_gallery", this.state.photo_gallery);
    formdata.append("step", "1");

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(
        URLs.update_candidate_details + "?token=" + a_token,
        formdata,
        config
      )
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState({
            loading: false,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };
  validateAdharNo = (e) => {
    var value = e.target.value;
    value = value
      .replace(/\D/g, "")
      .split(/(?:([\d]{4}))/g)
      .filter((s) => s.length > 0)
      .join(" ");
    this.setState({
      adhar_no: value,
    });
  };
  handleChange = (checked) => {
    this.setState(
      {
        have_adhar: checked,
      },
      () => {
        if (checked == false) {
          this.setState({ adhar_no: "" });
        }
      }
    );
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Sahaas ID</label>
            <input
              type="number"
              className="form-control"
              value={this.state.sahaas_id}
              disabled={true}
            />
          </div>
          <div className="col-lg-6"></div>
          <div className="col-lg-6">
            <div className="form-group">
              <label className="font-weight-medium">Have aadhar: </label>{" "}
              &nbsp;&nbsp;&nbsp;
              <Switch
                onChange={this.handleChange}
                checked={this.state.have_adhar}
              />
            </div>
            {this.state.have_adhar == true && (
              <div>
                <label className="font-weight-medium">Adhar Card No</label>
                <input
                  type="text"
                  className="form-control border-1"
                  value={this.state.adhar_no}
                  // onChange={(e) => this.setState({ adhar_no: e.target.value })}
                  data-type="adhaar-number"
                  maxLength="19"
                  onChange={this.validateAdharNo}
                />
                <br />
              </div>
            )}
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Full Name</label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Gender</label>
            <select
              className="form-control border-1"
              value={this.state.gender}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ gender: e.target.value })}
            >
              <option value={""}>Select</option>
              <option value={"Male"}>Male</option>
              <option value={"Female"}>Female</option>
            </select>
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Date of Birth</label>
            <input
              type="date"
              className="form-control border-1"
              value={this.state.dob}
              onChange={(e) => this.setState({ dob: e.target.value })}
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Blood Group</label>
            <select
              className="form-control border-1"
              value={this.state.blood_group}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ blood_group: e.target.value })}
            >
              <option value={""}>Select</option>
              {this.state.bloodGroupList &&
                this.state.bloodGroupList.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Marital Status</label>
            <select
              className="form-control border-1"
              value={this.state.marital_status}
              style={{ color: "#000" }}
              onChange={(e) =>
                this.setState({ marital_status: e.target.value })
              }
            >
              <option value={""}>Select</option>
              <option value={"Married"}>Married</option>
              <option value={"Unmarried"}>Unmarried</option>
            </select>
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Religion</label>
            <select
              className="form-control border-1"
              value={this.state.religion}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ religion: e.target.value })}
            >
              <option value={""}>Select</option>
              {this.state.religion_list &&
                this.state.religion_list.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Caste</label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.caste}
              onChange={(e) => this.setState({ caste: e.target.value })}
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Language</label>
            <select
              className="form-control border-1"
              value={this.state.language}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ language: e.target.value })}
            >
              <option value={""}>Select</option>
              {this.state.language_list &&
                this.state.language_list.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Personal Contact No</label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.personal_contact}
              onChange={(e) => {
                let value = e.target.value.slice(0, 10);
                this.setState({ personal_contact: value });
              }}
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Photo Gallery</label>
            {this.state.photo_gallery_url && (
              <div style={{ marginBottom: 10 }}>
                <a
                  href={this.state.photo_gallery_url}
                  target="_blank"
                  style={{
                    padding: "4px 14px",
                    background: "#6201ed",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                >
                  Open
                </a>
                <br />
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              className="form-control border-1"
              onChange={this.handleImageChange}
            />
          </div>
        </div>
        <button
          className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
          type="button"
          style={{
            width: 130,
          }}
          onClick={() => this.submit()}
        >
          Update
        </button>
      </div>
    );
  }
}
