import React, { Component } from "react";
import { Checkbox, Radio, message } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";

export default class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adhar_card_front: "",
      adhar_card_back: "",
      ration_card: "",
      pan_card: "",
      disability_certificate: "",
      disability_card: "",
      educational_documents: "",
      income_certificate: "",
      candidate_id: "",

      adhar_card_front_url: "",
      adhar_card_back_url: "",
      ration_card_url: "",
      pan_card_url: "",
      disability_certificate_url: "",
      disability_card_url: "",
      educational_documents_url: "",
      income_certificate_url: "",
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    // Get the value of the 'id' parameter
    const cid = searchParams.get("cid");
    if (cid) {
      this.setState(
        {
          candidate_id: cid,
        },
        () => this.get_list()
      );
    } else {
      window.location.assign("/submitted-forms");
    }
  }
  get_list = () => {
    let params = {
      admin_id: localStorage.getItem("admin_id"),
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.candidate_id == this.state.candidate_id) {
              let adhar_card_front_url = item.adhar_card_front
                ? URLs.BASE_URL + item.adhar_card_front
                : "";
              let adhar_card_back_url = item.adhar_card_back
                ? URLs.BASE_URL + item.adhar_card_back
                : "";
              let ration_card_url = item.ration_card
                ? URLs.BASE_URL + item.ration_card
                : "";
              let pan_card_url = item.pan_card
                ? URLs.BASE_URL + item.pan_card
                : "";
              let disability_certificate_url = item.disability_certificate
                ? URLs.BASE_URL + item.disability_certificate
                : "";
              let disability_card_url = item.disability_card
                ? URLs.BASE_URL + item.disability_card
                : "";
              let educational_documents_url = item.educational_documents
                ? URLs.BASE_URL + item.educational_documents
                : "";
              let income_certificate_url = item.income_certificate
                ? URLs.BASE_URL + item.income_certificate
                : "";

              this.setState({
                adhar_card_front_url: adhar_card_front_url,
                adhar_card_back_url: adhar_card_back_url,
                ration_card_url: ration_card_url,
                pan_card_url: pan_card_url,
                disability_certificate_url: disability_certificate_url,
                disability_card_url: disability_card_url,
                educational_documents_url: educational_documents_url,
                income_certificate_url: income_certificate_url,
              });
              return;
            }
          }
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  submit = () => {
    if (!this.state.candidate_id) {
      message.warning("Invalid request. Please try again.");
      return;
    }
    let formdata = new FormData();
    formdata.append("adhar_card_front", this.state.adhar_card_front);
    formdata.append("adhar_card_back", this.state.adhar_card_back);
    formdata.append("ration_card", this.state.ration_card);
    formdata.append("pan_card", this.state.pan_card);
    formdata.append(
      "disability_certificate",
      this.state.disability_certificate
    );
    formdata.append("disability_card", this.state.disability_card);
    formdata.append("educational_documents", this.state.educational_documents);
    formdata.append("income_certificate", this.state.income_certificate);
    formdata.append("id", this.state.candidate_id);
    formdata.append("step", "7");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        // "Content-Type": "application/json",
      },
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(
        URLs.update_candidate_details + "?token=" + a_token,
        formdata,
        config
      )
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.set();
            }
          );
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Adhar Card (Front)</label>
            {this.state.adhar_card_front_url && (
              <div style={{ marginBottom: 10 }}>
                <a
                  href={this.state.adhar_card_front_url}
                  target="_blank"
                  style={{
                    padding: "4px 14px",
                    background: "#6201ed",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                >
                  Open
                </a>
                <br />
              </div>
            )}
            <input
              type="file"
              className="form-control border-1"
              onChange={(e) =>
                this.setState({ adhar_card_front: e.target.files[0] })
              }
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Adhar Card (Back)</label>
            {this.state.adhar_card_back_url && (
              <div style={{ marginBottom: 10 }}>
                <a
                  href={this.state.adhar_card_back_url}
                  target="_blank"
                  style={{
                    padding: "4px 14px",
                    background: "#6201ed",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                >
                  Open
                </a>
                <br />
              </div>
            )}
            <input
              type="file"
              className="form-control border-1"
              onChange={(e) =>
                this.setState({ adhar_card_back: e.target.files[0] })
              }
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Ration Card</label>
            {this.state.ration_card_url && (
              <div style={{ marginBottom: 10 }}>
                <a
                  href={this.state.ration_card_url}
                  target="_blank"
                  style={{
                    padding: "4px 14px",
                    background: "#6201ed",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                >
                  Open
                </a>
                <br />
              </div>
            )}
            <input
              type="file"
              className="form-control border-1"
              onChange={(e) =>
                this.setState({ ration_card: e.target.files[0] })
              }
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Pan Card</label>
            {this.state.pan_card_url && (
              <div style={{ marginBottom: 10 }}>
                <a
                  href={this.state.pan_card_url}
                  target="_blank"
                  style={{
                    padding: "4px 14px",
                    background: "#6201ed",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                >
                  Open
                </a>
                <br />
              </div>
            )}
            <input
              type="file"
              className="form-control border-1"
              onChange={(e) => this.setState({ pan_card: e.target.files[0] })}
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Disability Certificate</label>
            {this.state.disability_certificate_url && (
              <div style={{ marginBottom: 10 }}>
                <a
                  href={this.state.disability_certificate_url}
                  target="_blank"
                  style={{
                    padding: "4px 14px",
                    background: "#6201ed",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                >
                  Open
                </a>
                <br />
              </div>
            )}
            <input
              type="file"
              className="form-control border-1"
              onChange={(e) =>
                this.setState({ disability_certificate: e.target.files[0] })
              }
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Disability Card</label>
            {this.state.disability_card_url && (
              <div style={{ marginBottom: 10 }}>
                <a
                  href={this.state.disability_card_url}
                  target="_blank"
                  style={{
                    padding: "4px 14px",
                    background: "#6201ed",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                >
                  Open
                </a>
                <br />
              </div>
            )}
            <input
              type="file"
              className="form-control border-1"
              onChange={(e) =>
                this.setState({ disability_card: e.target.files[0] })
              }
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Income certificate</label>
            {this.state.income_certificate_url && (
              <div style={{ marginBottom: 10 }}>
                <a
                  href={this.state.income_certificate_url}
                  target="_blank"
                  style={{
                    padding: "4px 14px",
                    background: "#6201ed",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                >
                  Open
                </a>
                <br />
              </div>
            )}
            <input
              type="file"
              className="form-control border-1"
              onChange={(e) =>
                this.setState({ income_certificate: e.target.files[0] })
              }
            />
          </div>
          <div className="form-group col-lg-6">
            <label className="font-weight-medium">Educational Documents</label>
            {this.state.educational_documents_url && (
              <div style={{ marginBottom: 10 }}>
                <a
                  href={this.state.educational_documents_url}
                  target="_blank"
                  style={{
                    padding: "4px 14px",
                    background: "#6201ed",
                    color: "#fff",
                    borderRadius: 2,
                  }}
                >
                  Open
                </a>
                <br />
              </div>
            )}
            <input
              type="file"
              className="form-control border-1"
              multiple={true}
              onChange={(e) =>
                this.setState({ educational_documents: e.target.files[0] })
              }
            />
          </div>
        </div>
        <button
          className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
          type="button"
          style={{
            width: 130,
          }}
          onClick={() => this.submit()}
        >
          Update Details
        </button>
      </div>
    );
  }
}
