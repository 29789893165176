import React, { Component } from "react";
import { message } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";
import helperfunctions from "../../utils/functions";

export default class AddressDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state_list: [],
      relation_list: [
        "Father",
        "Mother",
        "Brother",
        "Sister",
        "Wife",
        "Son",
        "Daughter",
      ],
      house_no: "",
      selectedState: "",
      district: "",
      taluka: "",
      pincode: "",
      name: "",
      relation: "",
      contact_no: "",
      candidate_id: "",
      state_list: [],
      district_list: [],
      taluka_list: [],
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    // Get the value of the 'id' parameter
    const cid = searchParams.get("cid");
    if (cid) {
      this.setState(
        {
          candidate_id: cid,
        },
        () => this.get_list()
      );
    } else {
      window.location.assign("/submitted-forms");
    }
    this.get_district_list();
    this.get_state_list();
  }
  get_list = () => {
    let params = {
      admin_id: localStorage.getItem("admin_id"),
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.candidate_id == this.state.candidate_id) {
              this.setState(
                {
                  house_no: item.area_hourse_no,
                  selectedState: item.state_id,
                  district: item.district_id,
                  taluka: item.taluka_id,
                  pincode: item.pincode,
                  name: item.emergency_name,
                  relation: item.emergency_relation,
                  contact_no: item.emergency_contact,
                },
                () => this.get_taluka_list()
              );
              return;
            }
          }
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  get_state_list = () => {
    let a_token = localStorage.getItem("a_token");
    axios
      .get(URLs.get_state_list + "?token=" + a_token)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            state_list: response.data.data,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {});
  };
  get_taluka_list = () => {
    let params = {
      d_id: this.state.district,
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.get_taluka_list + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            taluka_list: response.data.data,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {});
  };
  get_district_list = () => {
    let a_token = localStorage.getItem("a_token");
    axios
      .get(URLs.get_district_list + "?token=" + a_token)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            district_list: response.data.data,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {});
  };
  submit = () => {
    if (!this.state.candidate_id) {
      message.warning("Invalid request. Please try again.");
      return;
    }
    if (
      this.state.house_no &&
      helperfunctions.containsOnlyNumbers(this.state.house_no) == false
    ) {
      message.warning("Invalid House no format.");
      return;
    }
    if (
      this.state.contact_no &&
      helperfunctions.containsOnlyNumbers(this.state.contact_no) == false
    ) {
      message.warning("Invalid contact no format.");
      return;
    }
    if (this.state.contact_no && this.state.contact_no.length != 10) {
      message.warning("Invalid Contact no format");
      return;
    }
    if (
      this.state.pincode &&
      helperfunctions.containsOnlyNumbers(this.state.pincode) == false
    ) {
      message.warning("Invalid Pincode no format.");
      return;
    }
    if (this.state.pincode && this.state.pincode.length != 6) {
      message.warning("Invalid Pincode");
      return;
    }
    let params = {
      house_no: this.state.house_no,
      selectedState: this.state.selectedState,
      district: this.state.district,
      taluka: this.state.taluka,
      pincode: this.state.pincode,
      name: this.state.name,
      relation: this.state.relation,
      contact_no: this.state.contact_no,
      id: this.state.candidate_id,
      step: "2",
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.update_candidate_details + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.set();
            }
          );
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };
  render() {
    return (
      <>
        <div className="row">
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">House No / Area</label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.house_no}
              onChange={(e) => this.setState({ house_no: e.target.value })}
            />
          </div>
          <div className="col-lg-6"></div>
          <div className="col-lg-12">
            <h4 style={{ fontSize: 22 }}>At Post - </h4>
            <hr />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">State</label>
            <select
              className="form-control border-1"
              value={this.state.selectedState}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ selectedState: e.target.value })}
            >
              <option value={""}>Select</option>
              {this.state.state_list &&
                this.state.state_list.map((item, index) => {
                  return (
                    <option value={item.state_id} key={index}>
                      {item.state_name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">District</label>
            <select
              className="form-control border-1"
              value={this.state.district}
              style={{ color: "#000" }}
              onChange={(e) => {
                this.setState(
                  {
                    district: e.target.value,
                  },
                  () => this.get_taluka_list()
                );
              }}
            >
              <option value={""}>Select</option>
              {this.state.district_list &&
                this.state.district_list.map((item, index) => {
                  return (
                    <option value={item.district_id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Taluka</label>
            <select
              className="form-control border-1"
              value={this.state.taluka}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ taluka: e.target.value })}
            >
              <option value={""}>Select</option>
              {this.state.taluka_list &&
                this.state.taluka_list.map((item, index) => {
                  return (
                    <option value={item.taluka_id} key={index}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Pincode</label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.pincode}
              onChange={(e) => {
                let value = e.target.value.slice(0, 6);
                this.setState({ pincode: value });
              }}
            />
          </div>
          <div className="col-lg-12">
            <hr />
            <h4 style={{ fontSize: 22 }}>In Case Of Emergency - </h4>
            <hr />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Name</label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Relation</label>
            <select
              className="form-control border-1"
              value={this.state.relation}
              style={{ color: "#000" }}
              onChange={(e) => this.setState({ relation: e.target.value })}
            >
              <option value={""}>Select</option>
              {this.state.relation_list &&
                this.state.relation_list.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-lg-6">
            <label className="pt-2">Contact No</label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.contact_no}
              onChange={(e) => {
                let value = e.target.value.slice(0, 10);
                this.setState({ contact_no: value });
              }}
            />
          </div>
        </div>
        <button
          className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
          type="button"
          style={{
            width: 130,
          }}
          onClick={() => this.submit()}
        >
          Update Details
        </button>
      </>
    );
  }
}
