const helperfunctions = {
	containsOnlyNumbers: function (inputString) {
	    // Regular expression to match only numeric characters
	    var numericRegex = /^[0-9]+$/;
	    // Check if the input string contains only numeric characters
	    return numericRegex.test(inputString);
	},	
	validateEmail: function(email) {
	  // Regular expression for email validation
	  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	  return re.test(email);
	}
}
export default helperfunctions;