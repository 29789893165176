import React, { Component } from "react";
import { Radio, message, Checkbox } from "antd";
import URLs from "../../utils/apis";
import axios from "axios";
import helperfunctions from "../../utils/functions";

export default class GovermentFacilities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ration_card_types: ["NA", "White", "Orange", "Yellow", "Antodaya"],
      benefits: [
        "ST pass",
        "Railway Pass",
        "Edu. Scholarship",
        "Pension",
        "other",
      ],
      propertyTaxConsession: "",
      localWalefareFund: "",
      ration_card_type: "",
      ration_card_no: "",
      benefit_name: [],
      candidate_id: "",
    };
  }
  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    // Get the value of the 'id' parameter
    const cid = searchParams.get("cid");
    if (cid) {
      this.setState(
        {
          candidate_id: cid,
        },
        () => this.get_list()
      );
    } else {
      window.location.assign("/submitted-forms");
    }
  }
  get_list = () => {
    let params = {
      admin_id: localStorage.getItem("admin_id"),
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.get_candidate_list + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          for (let item of response.data.data) {
            if (item.candidate_id == this.state.candidate_id) {
              this.setState({
                propertyTaxConsession: item.propery_tax_consession,
                localWalefareFund: item.local_walefare_fund,
                ration_card_type: item.ration_card_type,
                ration_card_no: item.ration_card_no,
                benefit_name: item.gov_benifits
                  ? JSON.parse(item.gov_benifits)
                  : [],
              });
              return;
            }
          }
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Warning:", error);
      });
  };
  submit = () => {
    if (!this.state.candidate_id) {
      message.warning("Invalid request. Please try again.");
      return;
    }
    if (this.state.ration_card_no.includes("-")) {
      message.warning("Invalid Ration card no format.");
      return;
    }
    if (
      this.state.ration_card_no &&
      helperfunctions.containsOnlyNumbers(this.state.ration_card_no) == false
    ) {
      message.warning("Invalid Ration card no format.");
      return;
    }
    if (this.state.ration_card_no && this.state.ration_card_no.length != 10) {
      message.warning("Invalid Ration card no format.");
      return;
    }
    let params = {
      propertyTaxConsession: this.state.propertyTaxConsession,
      localWalefareFund: this.state.localWalefareFund,
      ration_card_type: this.state.ration_card_type,
      ration_card_no: this.state.ration_card_no,
      benefit_name: JSON.stringify(this.state.benefit_name),
      id: this.state.candidate_id,
      step: "4",
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.update_candidate_details + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              loading: false,
            },
            () => {
              this.props.set();
            }
          );
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        } else {
          message.warning(response.data.message);
          this.setState({
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Something went wrong.Please try again.");
      });
  };
  handleChangeTaxConsession = (event) => {
    this.setState({ propertyTaxConsession: event.target.value });
  };
  handleChangeWalefareFund = (event) => {
    this.setState({ localWalefareFund: event.target.value });
  };
  onChangeScheme = (e) => {
    let arr = this.state.benefit_name;
    if (arr.includes(e.target.value)) {
      let temparr = [];
      for (let item of arr) {
        if (item != e.target.value) {
          temparr.push(item);
        }
      }
      this.setState({
        benefit_name: temparr,
      });
    } else {
      arr.push(e.target.value);
      this.setState({
        benefit_name: arr,
      });
    }
  };
  render() {
    return (
      <div>
        <div className="row">
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">Ration card type</label>
            <select
              className="form-control border-1"
              value={this.state.ration_card_type}
              style={{ color: "#000" }}
              onChange={(e) =>
                this.setState({ ration_card_type: e.target.value })
              }
            >
              <option value={""}>Select</option>
              {this.state.ration_card_types &&
                this.state.ration_card_types.map((item, index) => {
                  return (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="form-group col-lg-6">
            <label className="pt-2 font-weight-medium">
              Enter ration card no
            </label>
            <input
              type="text"
              className="form-control border-1"
              value={this.state.ration_card_no}
              onChange={(e) => {
                let value = e.target.value.slice(0, 10);
                this.setState({ ration_card_no: value });
              }}
            />
          </div>
          <div className="col-lg-6">
            <label className="pt-2 font-weight-medium">
              Benefit of govt. schemes
            </label>
            <div>
              <Checkbox
                onChange={this.onChangeScheme}
                value="ST pass"
                checked={
                  this.state.benefit_name.includes("ST pass") ? true : false
                }
              >
                ST pass
              </Checkbox>

              <Checkbox
                onChange={this.onChangeScheme}
                value="Railway Pass"
                checked={
                  this.state.benefit_name.includes("Railway Pass")
                    ? true
                    : false
                }
              >
                Railway Pass
              </Checkbox>

              <Checkbox
                onChange={this.onChangeScheme}
                value="Edu. Scholarship"
                checked={
                  this.state.benefit_name.includes("Edu. Scholarship")
                    ? true
                    : false
                }
              >
                Edu. Scholarship
              </Checkbox>

              <Checkbox
                onChange={this.onChangeScheme}
                value="Pension"
                checked={
                  this.state.benefit_name.includes("Pension") ? true : false
                }
              >
                Pension
              </Checkbox>

              <Checkbox
                onChange={this.onChangeScheme}
                value="Other"
                checked={
                  this.state.benefit_name.includes("Other") ? true : false
                }
              >
                Other
              </Checkbox>
            </div>
          </div>
          <div className="col-lg-6"></div>
          <div className="col-lg-6">
            <div className="form-group pt-4">
              <label className="font-weight-medium">
                Property Tax Concession:{" "}
              </label>{" "}
              <br />
              <Radio.Group
                onChange={this.handleChangeTaxConsession}
                value={this.state.propertyTaxConsession}
              >
                <Radio value={"Yes"}>Yes</Radio>
                <Radio value={"No"}>No</Radio>
              </Radio.Group>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group pt-4">
              <label className="font-weight-medium">Local Welfare Fund: </label>
              <br />
              <Radio.Group
                onChange={this.handleChangeWalefareFund}
                value={this.state.localWalefareFund}
              >
                <Radio value={"Yes"}>Yes</Radio>
                <Radio value={"No"}>No</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
        <button
          className="mt-2 btn btn-block btn-primary font-weight-medium auth-form-btn"
          type="button"
          style={{
            width: 130,
          }}
          onClick={() => this.submit()}
        >
          Update Details
        </button>
      </div>
    );
  }
}
