import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import DistrictWiseChart from "./components/charts/DistrictWiseChart";
import URLs from "./utils/apis";
import axios from "axios";
import { message } from "antd";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: 0,
      volunteers: 0,
      loading: false,
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    this.setState({ loading: true });
    let admin_id = localStorage.getItem("admin_id");
    let a_token = localStorage.getItem("a_token");
    axios
      .get(
        URLs.get_dashboard_data + "?admin_id=" + admin_id + "&token=" + a_token
      )
      .then((response) => {
        console.log("response", response);
        if (response.data.status == "1") {
          this.setState({
            candidates: response.data.data.candidates,
            volunteers: response.data.data.volunteers,
            loading: false,
          });
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div>
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            {/* <!-- partial:partials/_settings-panel.html --> */}
            <Sidebar />
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                        <h5 className="mr-4 mb-0 font-weight-bold">
                          Dashboard
                        </h5>
                        <div className="d-flex align-items-baseline dashboard-breadcrumb"></div>
                      </div>
                      <div className="d-flex">
                        <div className="btn-group mr-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-3 grid-margin stretch-card">
                    <div className="card icon-card-primary">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="icon mb-0 mb-md-2 mb-xl-0 mr-2">
                            <box-icon
                              name="user-circle"
                              color="#fff"
                              size="18px"
                            ></box-icon>
                          </div>
                          <p className="font-weight-medium mb-0">Candidates</p>
                        </div>
                        <div className="d-flex align-items-center mt-3 flex-wrap">
                          <h3 className="font-weight-normal mb-0 mr-2">
                            {this.state.candidates}
                          </h3>
                          <div className="badge badge-outline-light badge-pill mt-md-2 mt-xl-0"></div>
                        </div>
                        <small className="font-weight-medium d-block mt-2">
                          All Time Total
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-3 grid-margin stretch-card">
                    <div className="card icon-card-success">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="icon mb-0 mb-md-2 mb-xl-0 mr-2">
                            <i className="mdi mdi-truck"></i>
                          </div>
                          <p className="font-weight-medium mb-0">Volunteers</p>
                        </div>
                        <div className="d-flex align-items-center mt-3 flex-wrap">
                          <h3 className="font-weight-normal mb-0 mr-2">
                            {this.state.volunteers}
                          </h3>
                          <div className="badge badge-outline-light badge-pill mt-md-2 mt-xl-0"></div>
                        </div>
                        <small className="font-weight-medium d-block mt-2">
                          All Time Total
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-start justify-content-between">
                          <p className="card-title flex-grow">
                            District Wise Candidates
                          </p>
                          <div className="d-flex align-items-center">
                            <div className="dropdown dropright card-menu-dropdown"></div>
                          </div>
                        </div>
                        <DistrictWiseChart />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 grid-margin stretch-card"></div>
                </div>
              </div>
              {/* <!-- content-w/rapper ends --> */}
              {/* <!-- partial:partials/_footer.html --> */}
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 . All rights reserved.
                  </span>
                </div>
              </footer>
              {/* <!-- partial --> */}
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    );
  }
}
