import React from "react";
import "boxicons";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./app/Login";
import Dashboard from "./app/Dashboard";
import Candidates from "./app/Candidates";
import Users from "./app/Users";
import Settings from "./app/Settings";
import EditCandidate from "./app/EditCandidate";
import ForgotPassword from "./app/ForgotPassword";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/candidates" exact element={<Candidates />} />
          <Route path="/volunteer-users" exact element={<Users />} />
          <Route path="/settings" exact element={<Settings />} />
          <Route path="/edit-candidate" exact element={<EditCandidate />} />
          <Route path="/forgot-password" exact element={<ForgotPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
