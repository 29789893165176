import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import { Table, Modal, Spin, message, Switch, Menu, Dropdown } from "antd";
import URLs from "./utils/apis";
import axios from "axios";
import helperfunctions from "./utils/functions";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      crecords: [],
      loading: false,

      modalStatus: false,
      loader: false,
      name: "",
      email: "",
      mobile: "",
      formLoader: false,

      edit_id: "",
      edit_name: "",
      edit_email: "",
      edit_mobile: "",
      edit_modal_status: false,
    };
  }
  componentDidMount() {
    this.get_list();
  }
  get_list = () => {
    this.setState({ loading: true });
    let district_id = localStorage.getItem("ad_id");
    let a_token = localStorage.getItem("a_token");
    axios
      .get(
        URLs.get_volunteers +
          "?district_id=" +
          district_id +
          "&token=" +
          a_token
      )
      .then((response) => {
        if (response.data.status == "1") {
          console.log("vol res", response.data.data);
          this.setState({
            loading: false,
            records: response.data.data,
            crecords: response.data.data,
          });
          return;
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  onChangeActive = (event, item) => {
    console.log("event", event);
    let status = "1";
    if (event == true) {
      status = "1";
    } else {
      status = "0";
    }
    let params = {
      status,
      id: item.volunteer_id,
    };
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.update_volunteer_status + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.get_list();
          return;
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        console.error("Warning:", error);
      });
  };
  openAddModal = () => {
    this.setState({
      modalStatus: true,
    });
  };
  save_user = () => {
    if (!this.state.name) {
      message.warning("Please enter user's full name!");
      return;
    }
    if (!this.state.email) {
      message.warning("Please enter email address.");
      return;
    }
    if (helperfunctions.validateEmail(this.state.email) == false) {
      message.warning("Invalid format of email address.");
      return;
    }
    if (!this.state.mobile) {
      message.warning("Please enter mobile no.");
      return;
    }
    if (helperfunctions.containsOnlyNumbers(this.state.mobile) == false) {
      message.warning("Invalid format of mobile no.");
      return;
    }
    if (this.state.mobile.length != 10) {
      message.warning("Invalid mobile no.");
      return;
    }
    let params = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      admin_id: localStorage.getItem("admin_id"),
    };
    this.setState({ formLoader: true });
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.add_new_volunteer + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formLoader: false,
              name: "",
              email: "",
              mobile: "",
              modalStatus: false,
            },
            () => this.get_list()
          );
          return;
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  close_modal = () => {
    this.setState({
      modalStatus: false,
    });
  };
  close_edit_modal = () => {
    this.setState({
      edit_modal_status: false,
      edit_id: "",
      edit_name: "",
      edit_email: "",
      edit_mobile: "",
    });
  };
  show_edit_modal = (item) => {
    this.setState({
      edit_id: item.volunteer_id,
      edit_name: item.name,
      edit_email: item.email,
      edit_mobile: item.mobile,
      edit_city: item.city,
      edit_district: item.district,
      edit_modal_status: true,
    });
  };
  update_user = () => {
    if (!this.state.edit_id) {
      message.warning("Invalid request.");
      return;
    }
    if (!this.state.edit_name) {
      message.warning("Please enter user's full name!");
      return;
    }
    if (!this.state.edit_email) {
      message.warning("Please enter email address.");
      return;
    }
    if (helperfunctions.validateEmail(this.state.edit_email) == false) {
      message.warning("Invalid format of email address.");
      return;
    }
    if (!this.state.edit_mobile) {
      message.warning("Please enter mobile no.");
      return;
    }
    if (helperfunctions.containsOnlyNumbers(this.state.edit_mobile) == false) {
      message.warning("Invalid format of mobile no.");
      return;
    }
    if (this.state.edit_mobile.length != 10) {
      message.warning("Invalid mobile no.");
      return;
    }
    let params = {
      name: this.state.edit_name,
      email: this.state.edit_email,
      mobile: this.state.edit_mobile,
      id: this.state.edit_id,
    };
    this.setState({ formLoader: true });
    let a_token = localStorage.getItem("a_token");
    axios
      .post(URLs.update_volunteer_details + "?token=" + a_token, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          this.setState(
            {
              formLoader: false,
              edit_name: "",
              edit_email: "",
              edit_mobile: "",
              edit_modal_status: false,
            },
            () => this.get_list()
          );
          return;
        } else if (response.data.status == "2") {
          message.warning(response.data.message);
          setTimeout(() => {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("admin_name");
            localStorage.removeItem("a_token");
            window.location.assign("/");
          }, 2000);
        } else {
          this.setState({ formLoader: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ formLoader: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  filter_data = () => {
    let filtered_data = [];
    let searchInput = this.state.searchText;
    let list = this.state.crecords;
    for (let item of list) {
      if (
        (item.name &&
          item.name.toLowerCase().includes(searchInput.toLowerCase())) ||
        (item.email &&
          item.email.toLowerCase().includes(searchInput.toLowerCase())) ||
        (item.mobile &&
          item.mobile.toLowerCase().includes(searchInput.toLowerCase()))
      ) {
        filtered_data.push(item);
      }
    }
    this.setState({
      records: filtered_data,
    });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: index,
          id: item.volunteer_id,
          name: item.name,
          email: item.email,
          mobile: item.mobile,
          active: item,
          action: item,
        };
      });
    const columns = [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Mobile Number",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
      },
      // {
      //   title: "Device Id",
      //   dataIndex: "device_id",
      //   key: "device_id",
      // },
      {
        title: "Active/In-active",
        dataIndex: "active",
        key: "active",
        render: (item) => {
          return (
            <Switch
              onChange={(e) => this.onChangeActive(e, item)}
              checked={item.is_active == "1" ? true : false}
            />
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          const menu = (
            <Menu className="action-menu participant-action-menu">
              <Menu.Item key={`show_edit_modal_${item.index}`}>
                <a
                  className="action-buttons"
                  href="#!"
                  onClick={() => this.show_edit_modal(item)}
                >
                  <i
                    className="fas fa-file-alt"
                    style={{ color: "#000", fontSize: 18 }}
                  ></i>
                  <span style={{ fontSize: 14, marginLeft: 10 }}>
                    Show Edit Modal
                  </span>
                </a>
              </Menu.Item>
            </Menu>
          );

          return (
            <div style={{ width: "100%" }}>
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link action-link"
                  onClick={(e) => e.preventDefault()}
                  style={{
                    background: "#ddd",
                    paddingTop: 13,
                    borderRadius: 4,
                  }}
                >
                  <box-icon name="dots-vertical" color="#000"></box-icon>
                </a>
              </Dropdown>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            {/* <!-- partial:partials/_settings-panel.html --> */}
            <Sidebar />
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="card">
                  <div className="card-body">
                    <h4
                      className="card-title"
                      style={{
                        fontSize: 24,
                        fontWeight: "600",
                      }}
                    >
                      Registered Volunteers
                    </h4>
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <div className="row">
                            <div className="col-lg-4">
                              <div class="form-group">
                                <label for="exampleInputUsername1">
                                  Search
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputUsername1"
                                  placeholder="Search"
                                  style={{ width: "100%" }}
                                  value={this.state.searchText}
                                  onChange={(e) =>
                                    this.setState(
                                      {
                                        searchText: e.target.value,
                                      },
                                      () => this.filter_data()
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-5"></div>
                            <div className="col-lg-3">
                              <button
                                type="button"
                                class="btn btn-primary mr-2"
                                onClick={() => this.openAddModal()}
                                style={{
                                  position: "relative",
                                  top: 28,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <box-icon
                                  type="solid"
                                  name="plus-circle"
                                  color={"#fff"}
                                ></box-icon>{" "}
                                &nbsp; Add New Volunteer
                              </button>
                            </div>
                          </div>
                          <Table
                            dataSource={dataSource}
                            loading={this.state.loading}
                            columns={columns}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- content-w/rapper ends --> */}
              {/* <!-- partial:partials/_footer.html --> */}
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 . All rights reserved.
                  </span>
                </div>
              </footer>
              {/* <!-- partial --> */}
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>

        <Modal
          title="Add New Admin User"
          visible={this.state.modalStatus}
          onOk={() => this.save_user()}
          onCancel={() => this.close_modal()}
          okText="Save"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formLoader}>
            <div style={{ paddingTop: 30 }}>
              <div class="form-group">
                <label for="exampleInputUsername1">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputUsername1"
                  style={{ width: "100%" }}
                  value={this.state.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </div>
              <div class="form-group">
                <label for="exampleInputUsername1">
                  Email Address <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputUsername1"
                  style={{ width: "100%" }}
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              <div class="form-group">
                <label for="exampleInputUsername1">
                  Mobile No. <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputUsername1"
                  style={{ width: "100%" }}
                  value={this.state.mobile}
                  onChange={(e) => {
                    let value = e.target.value.slice(0, 10);
                    this.setState({ mobile: value });
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>

        {/* Edit modal start  */}
        <Modal
          title="Update Admin User"
          visible={this.state.edit_modal_status}
          onOk={() => this.update_user()}
          onCancel={() => this.close_edit_modal()}
          okText="Update"
          cancelText="Cancel"
        >
          <Spin spinning={this.state.formLoader}>
            <div style={{ paddingTop: 30 }}>
              <div class="form-group">
                <label for="exampleInputUsername1">
                  Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputUsername1"
                  style={{ width: "100%" }}
                  value={this.state.edit_name}
                  onChange={(e) => this.setState({ edit_name: e.target.value })}
                />
              </div>
              <div class="form-group">
                <label for="exampleInputUsername1">
                  Email Address <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="exampleInputUsername1"
                  style={{ width: "100%" }}
                  value={this.state.edit_email}
                  onChange={(e) =>
                    this.setState({ edit_email: e.target.value })
                  }
                />
              </div>
              <div class="form-group">
                <label for="exampleInputUsername1">
                  Mobile No. <span className="text-danger">*</span>
                </label>
                <input
                  type="number"
                  class="form-control"
                  id="exampleInputUsername1"
                  style={{ width: "100%" }}
                  value={this.state.edit_mobile}
                  onChange={(e) => {
                    let value = e.target.value.slice(0, 10);
                    this.setState({ edit_mobile: value });
                  }}
                />
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
